const columns = (renderCell, renderDescriptionCell) => [
  {
    field: 'PN',
    headerName: 'PN',
    width: 200,
    hide: false,
    renderCell,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'Category',
    headerName: 'Category',
    width: 200,
    renderCell,
    hide: false,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'Name',
    headerName: 'Name',
    width: 200,
    renderCell,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center',
    hide: false,
  },
  {
    field: 'OriginalPrice',
    headerName: 'Original Price',
    width: 120,
    renderCell,
    type: 'number',
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'UpdatedPrice',
    headerName: 'Updated Price',
    width: 120,
    renderCell,
    type: 'number',
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'Description',
    headerName: 'Description',
    hide: false,
    renderCell: renderDescriptionCell,
    width: 600,
    verticalAlign: 'middle',
    headerAlign: 'center',
    align: 'center'
  }
];

export default columns;
