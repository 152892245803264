import React, { useEffect, useState } from 'react';
import SyncMobileDevicesModal from '../ControlPanel/SyncMobileDevices/SyncMobileDevicesModal/index';
import { Box, gridClasses, Typography } from '@mui/material';
import styles from './styles';
import PropTypes from 'prop-types';
import Datagrid from '../Datagrid';
import { useSelector } from 'react-redux';
import columns from './table_config';
import { renderCell } from '../Datagrid/table_config';
import DescriptionImageModalDialog from '../DescriptionImageModalDialog';
import { useGridApiRef } from '@mui/x-data-grid-pro';

function PartsPriceUpdatePreview({ open, handleClose, isLoading, handleUpload, selectedFile, custom2 }) {
  const classes = styles();
  const gridApiRef = useGridApiRef();
  const parts = useSelector((state) => state.parts);
  const [rows, setRows] = useState([]);
  const [error, setError] = useState('');
  const [partImage, setPartImage] = useState({
    open: false,
    data: {}
  });

  useEffect(() => {
    if (selectedFile) {
      handleFileChange(selectedFile);
    }
  }, [selectedFile]);

  const handleFileChange = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const csvContent = e.target.result;
        const rowsData = parseCsv(csvContent);
        setRows(rowsData);
      };
      reader.readAsText(file);
    }
  };

  const parseCsv = (csvContent) => {
    const rows = csvContent.split('\n').filter(row => row.trim() !== '');

    const data = rows.slice(1).map((row, index) => {
      const columns = row.split(',').map(col => col.trim());

      if (columns.length !== 2) {
        setError("Invalid CSV format. The file must contain a maximum of two columns: 'PN' and 'Custom3'");
        return null;
      }

      const pn = columns[0];
      const custom3 = columns[1];
      const part = parts.listPartsByCompany.find(part => part.PN === pn);

      if (part) {
        const updatedPrice = Number(custom3) + (Number(custom3) * Number(custom2));

        return {
          ...part,
          grid_id: index + 1,
          OriginalPrice: Number(part.Price).toFixed(2),
          UpdatedPrice: updatedPrice.toFixed(2),
        };
      }
      return null;
    }).filter(row => row !== null);

    return data;
  };

  const handleFileUpload = () => {
    handleUpload(selectedFile, custom2);
  };

  const renderDescriptionCell = ({ value, row }) => (
      <Typography
        onClick={() => setPartImage({
          open: true, data: row
        })}
        style={{ fontSize: '12px', fontWeight: 'normal', textWrap: 'wrap', cursor: 'pointer' }}
      >{value}
      </Typography>
  );

  return (
    <>
      <SyncMobileDevicesModal
        open={open}
        onClose={handleClose}
        handleClose={handleClose}
        showCta2
        cta2Label={'Update'}
        cta2Action={handleFileUpload}
        cta2Disabled={isLoading || !selectedFile || !custom2 || error}
      >
      <Box className={classes.container}>
        {error && <Typography variant='h5'>{error}</Typography>}
        <Datagrid
          data={rows}
          columns={columns(renderCell, renderDescriptionCell)}
          apiRef={gridApiRef}
          getRowId={(row) => row.grid_id}
          height={'auto'}
          toolbar={{
            options: {
              columns: true,
              filters: true,
              density: false,
              export: false,
            },
          }}
          sx={{
            '.MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold !important',
            },
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
          }}
        />
      </Box>
      </SyncMobileDevicesModal>
      {partImage.open &&
        <DescriptionImageModalDialog
          data={partImage.data}
          image={partImage.data.Image}
          isOpen={partImage.open}
          onClose={() => setPartImage({
            open: false,
            data: {}
          })}
        />
      }
    </>
  );
}

PartsPriceUpdatePreview.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  isLoading: PropTypes.bool,
  handleUpload: PropTypes.func,
  selectedFile: PropTypes.any,
  custom2: PropTypes.any
};

export default PartsPriceUpdatePreview;
