import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => {
  return {
    container: {
      width: '90vw',
      maxWidth: '1850px',
      backgroundColor: '#FFF'
    }
  };
});

export default styles;
